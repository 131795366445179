import React, { useState, useEffect } from "react";
import { Button, Col, Row, Checkbox } from "antd";


import {
  LinkedinFilled,
  GithubOutlined,
  InstagramOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";

import { IconCard, PublicationCard } from "components/cards";
import { Separator } from "components/common";
import { ScholarIcon, XIcon } from "components/Icons";

import logo from "assets/imgs/avatar.jpg";
import publicationsData from "assets/data/publications.json";

// Styles

const gutter = [
  { xs: 8, sm: 16, md: 32 },
  { xs: 8, sm: 16, md: 32 },
];

const background = {
  background:
    'linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.4)), url("/img/backgrounds/alps.jpg")',
  backgroundSize: "cover",
  backgroundPosition: "center",
  margin: "1.5vh",
  padding: "min(11vh, 200px) 0",
  borderRadius: "15px",
  boxShadow: "inset 0 0 5px black",
};

const button = {
  fontSize: "1.8rem",
  border: "none",
};

const buttonLink = {
  fontSize: "17px",
  border: "none",
};

// Sections

const SocialLinks = () => {
  return (
    <Row justify="center">
      <Col>
        <Button href="https://www.linkedin.com/in/giuvecchio/" style={button}>
          <LinkedinFilled />
        </Button>
      </Col>

      <Col>
        <Button href="https://github.com/giuvecchio" style={button}>
          <GithubOutlined />
        </Button>
      </Col>

      <Col>
        <Button href="https://scholar.google.com/citations?user=TF1JBZYAAAAJ" style={button}>
          <ScholarIcon />
        </Button>
      </Col>

      <Col>
        <Button href="https://twitter.com/giuvecchio95" style={button}>
          <XIcon />
        </Button>
      </Col>

      <Col>
        <Button href="https://www.youtube.com/channel/UC-Szz5yGx2q4fgQzm3diG0Q/" style={button}>
          <YoutubeOutlined />
        </Button>
      </Col>

      <Col>
        <Button href="https://www.instagram.com/giuvecchio/" style={button}>
          <InstagramOutlined />
        </Button>
      </Col>

    </Row>
  );
};

const AboutSection = () => {
  return (
    <>
      <h2>Who am I?</h2>

      <Separator height={2} />

      <h5 className="description" style={{ hyphens: "manual" }}>
        Hey, I'm <b>Giuseppe</b>, an AI and Computer Graphics researcher.
        <Separator height={1} />
        My research focuses on <b>Gen AI</b> for <b>graphics</b>, and <b>learning strategies</b> to bridge the gap between simulation and reality.
        <Separator height={3} />
        When not immersed in pixels, I like to <b>travel</b> the world and <a href="https://www.instagram.com/giuvecchio/">capture moments</a> with my camera.
      </h5>

      <Separator height={5} />

      <Row gutter={[32, 32]}>
        <Col sm={24} md={12}>
          <img
            //className="ant-card"
            style={{ width: "100%", borderRadius: 15 }}
            src="/img/pictures/then-now.jpg"
            alt="then-now"
          />
        </Col>
        <Col sm={24} md={12} style={{ textAlign: "justify" }}>
          <p>I received my <b>Ph.D. </b> (2023) at the University of Catania, under the supervision of Prof. Concetto Spampinato. </p>
          <Separator height={1} />
          <p>During my Ph.D. I spent 3 months at the <b>University of Edinburgh</b> under the supervision of Prof. Stefano Albrech in the context of the HPC3 program. </p>
          <Separator height={1} />
          <p>I also did a 6 months internship at <b>Adobe Research</b>, Lyon, working on generative models for materials.</p>
        </Col>
      </Row>

      {/* <Separator height={4} />
      <h5 style={{ fontWeight: "normal" }}>If you're interested in collaborting with me on some cool projects drop an email.</h5> */}
      <Separator height={4} />
      <h5>Download my full <a href='/docs/CV_GiuseppeVecchio.pdf' download>CV</a>.</h5>
    </>
  );
};

const InterestsSection = () => {
  return (
    <>
      <h2>Research Interests</h2>
      <Separator height={1} />

      <Row gutter={gutter} justify="center">
        <IconCard
          title="GenAI"
          description="Learning based generative models for images, materials, 3D and more."
          icon="/img/icons/gen_ai.svg"
          link=""
        />

        <IconCard
          title="Computer Graphics"
          description="Solving graphics problems using using deep learning."
          icon="/img/icons/mat_gen.svg"
          link=""
        />

        <IconCard
          title="Sim-to-Real"
          description="Learning strategies to bridge the gap between simulation and reality."
          icon="/img/icons/gaming.svg"
        />
      </Row>
    </>
  );
};

const PublicationSection = ({ publications, onChange }) => {

  const allKeywords = Array.from(
    new Set(publicationsData.flatMap(publication => publication.keywords))
  ).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  return (
    <>
      <h2>Publications</h2>
      <Separator height={1} />

      <Row gutter={[16, 16]} justify="center">
        {allKeywords.map(keyword => (
          <Col key={keyword}>
            <Checkbox onChange={e => onChange(keyword, e.target.checked)}>
              {keyword}
            </Checkbox>
          </Col>
        ))}
      </Row>

      <Separator height={3} />

      <Row gutter={gutter} justify="center" align="middle">
        {publications.map((publication, index) => (
          <PublicationCard
            key={index}
            title={publication.title}
            description={publication.description}
            authors={publication.authors}
            icon={publication.icon}
            link={publication.link}
            arxiv={publication.arxiv}
            pdf={publication.pdf}
            huggingface={publication.huggingface}
            github={publication.github}
            youtube={publication.youtube}
            badge={publication.badge}
          />
        ))}
      </Row>

      <Separator height={2} />
      <div style={{ textAlign: "right" }}>
        <Button href="https://scholar.google.com/citations?user=TF1JBZYAAAAJ&hl">
          More on Google Scholar
        </Button>
      </div>
    </>
  );
};


// const ProjectsSection = () => {
//   return (
//     <>
//       <h2>Projects</h2>
//       <Separator height={1} />

//       <Row gutter={gutter} justify="center" align="middle">
//         <BigIconCard
//           title="StableMaterials"
//           description="Enhancing diversity in PBR material generation with diffusion models and semi-supervised training."
//           icon="/img/thumbnails/stablematerials.png"
//           link="https://gvecchio.com/stablematerials"
//         />

//         <BigIconCard
//           title="MatSynth"
//           description="A modern dataset of 4,000+ ultra-high resolution PBR materials."
//           icon="/img/thumbnails/matsynth.png"
//           link="https://gvecchio.com/matsynth/"
//           badge={"CVPR 2024!"}
//         />

//         <BigIconCard
//           title="ControlMat"
//           description="Diffusion based material capture model conditioned on input photographs."
//           icon="/img/thumbnails/controlmat.png"
//           link="https://www.gvecchio.com/controlmat"
//         />
//       </Row>
//       <Separator height={2} />
//       <div style={{ textAlign: "right" }}>
//         <Button>
//           <Link to="/projects">Explore more projects</Link>
//         </Button>
//       </div>
//     </>
//   );
// };

// Home Body

const Home = () => {
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [filteredPublications, setFilteredPublications] = useState(publicationsData);

  useEffect(() => {
    if (selectedKeywords.length === 0) {
      setFilteredPublications(publicationsData);
    } else {
      setFilteredPublications(
        publicationsData.filter(publication =>
          selectedKeywords.every(keyword => publication.keywords.includes(keyword))
        )
      );
    }
  }, [selectedKeywords]);

  const handleKeywordChange = (keyword, checked) => {
    setSelectedKeywords(prev =>
      checked ? [...prev, keyword] : prev.filter(k => k !== keyword)
    );
  };

  return (
    <>
      <div className="slide slide-first dotted">
        <div style={background}>
          <div className="container">
            <Row justify="center" align="middle" gutter={[32, 32]}>
              <Col>
                <img
                  src={logo}
                  alt="avatar"
                  style={{ maxWidth: "min(400px, 70vw)", borderRadius: "50%", padding: "min(40px, 3vw)" }}
                />
              </Col>
              <Col style={{ textAlign: "center" }}>
                <div>
                  <h1 style={{ margin: 0, fontWeight: "600", hyphens: "manual" }}>Giuseppe Vecchio</h1>
                  <h4 style={{ margin: 0 }}>Ph.D. in Computer Vision</h4>
                  <Button href="mailto:giuseppevecchio@hotmail.com" style={buttonLink}>giuseppevecchio [at] hotmail [dot] com</Button>
                  <Separator height={2} />
                  <SocialLinks />
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <Separator height={6} />

        <div className="container centered">
          <div>
            <AboutSection />
            <Separator height={6} />
            <InterestsSection />
            <Separator height={6} />
            <PublicationSection publications={filteredPublications} onChange={handleKeywordChange} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
