import { Badge, Card, Col } from "antd";

import {
    GithubOutlined,
    LinkOutlined,
    FilePdfOutlined,
    YoutubeOutlined,
} from "@ant-design/icons";

import { DeviceSizeView, Separator } from "components/common";
import { ArxivIcon } from "components/Icons";

const { Meta } = Card;

const PublicationCard = ({ title, description, authors, icon, link, arxiv, pdf, github, huggingface, youtube, badge }) => {
    const img = icon ? (<img
        alt={title}
        src={icon}
        style={{ maxHeight: "115px", margin: "0 10px 0 -6px", borderRadius: 10 }}
    />) : null;

    const content = (
        <>
            <b>{description}</b>
            <Separator height={1} />
            <h7>{authors}</h7>
        </>
    );

    let links = [
        link ? <a href={link} style={{ fontSize: "1.5rem" }}><LinkOutlined /></a> : null,
        arxiv ? <a href={arxiv} style={{ fontSize: "1.5rem" }}><ArxivIcon /></a> : null,
        pdf ? <a href={pdf} style={{ fontSize: "1.5rem" }}><FilePdfOutlined /></a> : null,
        github ? <a href={github} style={{ fontSize: "1.5rem" }}><GithubOutlined /></a> : null,
        youtube ? <a href={youtube} style={{ fontSize: "1.5rem" }}><YoutubeOutlined /></a> : null,
        huggingface ? <a href={huggingface} style={{ fontSize: "1.5rem" }}>🤗</a> : null,
    ];
    links = links.filter(element => { return element !== null; });

    const visibleBadge = badge ? "visible" : "hidden";
    return (
        <>
            <Col sm={24} md={24} style={{ width: "100%" }}>
                <DeviceSizeView screen_size="sm" mode="gt">
                    <Badge.Ribbon text={badge} color="red" style={{ visibility: visibleBadge }}>
                        <Card
                            hoverable
                            style={{ minWidth: "100%", textAlign: "left" }}
                            actions={links}
                        >
                            <Meta avatar={img} title={title} description={content} />
                        </Card>
                    </Badge.Ribbon>
                </DeviceSizeView>

                <DeviceSizeView screen_size="sm" mode="lt">
                    <Badge.Ribbon text={badge} color="red" style={{ visibility: visibleBadge }}>
                        <Card
                            hoverable
                            style={{ minWidth: "100%" }}
                            actions={links}
                        >
                            <Meta title={title} description={content} />
                        </Card>
                    </Badge.Ribbon>
                </DeviceSizeView>
            </Col>
        </>
    );
};

export default PublicationCard;